<template>
    <!-- <div class="search-tile-outer"> -->
        <!--<div class="search-tile" ref="tile" tabindex="0" @click="clickTile()" @keyup.enter="clickTile()">-->
        <div class="tile" ref="tile">
            <div class="search-tile-inner" ref="tileInner">
                <div class="field-title" ref="fieldTitle">
                    <!-- <a :href="refLink" v-if="refLink" target="_blank" title="Access legacy version here">{{name}}</a> -->
                    <!-- <template v-else>{{name}}</template> -->
                    {{name}}
                    <span v-if="subTitle" class="sub-title">{{subTitle}}</span>
                </div>
                <div class="tile-content-wrapper">
                    <div v-if="loading" class="loading"><div class="loader-bg"></div><span class="loader">Loading...</span></div>
                    <div class="tile-chart" ref="eChartsDOM" v-if="!serviceError"></div>
                    <div class="field-error-message error-large" v-if="serviceError">
                        Unable to load map data
                        <div class="error-code">
                        {{serviceError}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- </div> -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import * as echarts from 'echarts';
import globalMixins from '@/globalMixins';
export default {
    name: 'MapTile',
    components: {
    },
    mixins: [globalMixins],
    computed: {
        tileComponent () {
            return this.name.replace(/\s/g,'');
        },
        tileClass () {
            return this.name.replace(/\s/g,'-').toLowerCase();
        },
        ...mapGetters([
            'homePageMapData',
            'externalURLs',
            'homeMapSearchId'
        ])
    },
    props: {
        name: {
            required: true
        },
        subTitle: {
            required: false
        },
        description: {
            required: false
        }
    },
    data: function () {
        return {
            init: false,
            isActive: this.active,
            loading: true,
            width: 0,
            height: 0,
            minHeight: 0,
            baseMinHeight: 0,
            smallTilePercent: .25,
            largeTilePercent: .75,
            margin: 15,
            oppositeX: 0,
            newLastActiveY: 0,
            top: 0,
            left: 0,
            expanded: false,
            defaultDuration: 301,
            eChart: null,
            animate: true,
            resizeTimeout: null,
            serviceError: null,
            // searchParams: {
            //     search_type: "Advanced",
            //     facet_filters: {
            //         fiscal_years: [],
            //         org_names: [],
            //         agencies: [],
            //         states: [],
            //         countries: [],
            //         principal_investigators: []
            //     },
            //     summarized_by: "Agency",
            //     plotted_by: "count",
            //     limit_count: 100,
            //     criteria: {
            //         fiscal_years: "",
            //         // is_agency_admin: true,
            //         // agencies: "NIH"
            //     },
            //     search_id: this.searchId
            // }
            geoData: null,
            mapData: null,
            heatMapMinValue: 0,
            heatMapMaxValue: 0,
            searchParam: {
                search_id: null,
                // facet_filters: null,
                // view_by: 'Organizations',
                map_filters: {
                    states: [],
                    countries: ['UNITED STATES'],
                    cong_dists: []
                },
                home_page_map: true,
            },
            stateCodeToName: window.stateCodeToName,
            stateNameToCode: window.stateNameToCode,
        };
    },
    mounted () {
        this.eChartsInit();
    },
    methods: {
        ...mapActions([
            'setCurrentQueryDetailsAction',
            'setSearchFacetData',
            'setSearchMeta',
            'setSearchCriteria',
            'setSearchResults',
            'setHomePageMapData',
            'setSearchType',
            'setHomeMapSearchId'
        ]),
        eChartsInit () {
            let processClick = (event, name) => {

                    let stateCode = window.stateNameToCode[name.toUpperCase()];

                    if (event.ctrlKey || event.metaKey) {
                        let routeData = this.$router.resolve({
                            path: '/search/' + this.homeMapSearchId + '/projects/map',
                            query: {
                                states: stateCode
                            }
                        });
                        window.open(routeData.href, '_blank');
                    } else {
                        this.setSearchMeta(null);
                        this.setSearchResults(null);
                        this.setSearchCriteria(null);
                        this.setEditSearchCriteria(null);
                        this.setSearchFacetData(null);
                        this.setSearchType('Advanced');
                        this.clearSearchResults({resetFacets: true});
                        this.setCurrentQueryDetailsAction(null);

                        window.firstLoadFacets = {
                            states: [stateCode]
                        };
                        this.$nextTick(() => {
                            console.log(this.homeMapSearchId);
                            this.$router.push({
                                path: '/search/' + this.homeMapSearchId + '/projects/map',
                                query: {
                                    states: stateCode
                                }
                            });
                        });
                    }
                };

            this.eChart = echarts.init(this.$refs.eChartsDOM);

            this.eChart.on('click', (params) => {
                if (params && params.name) {
                    processClick(params.event.event, params.name);
                }
            });

            window.addEventListener('resize', () => {
                this.resizeChart();
            });

            // if (!this.homePageMapData) {
            //     this.mapData = this.homePageMapData;
            // }

            window.getJSON({
                url: '/data/geo-usa.json',
                // callback: this.eChartsBuild
                callback: this.setGeoDataAndGetChartData
                // callback: this.getChartData
            });

            this.inti = true;
        },
        setGeoDataAndGetChartData (geoData) {
            this.geoData = geoData;

            if (!this.homePageMapData) {
                this.getChartData();
            } else {
                this.eChartsBuild();
            }
        },
        getChartData () {
            window.getMapData(this.searchParam, this.parseData, this.eChartsBuild, 'DataByCountry');
        },
        parseData (data, callback) {

            if (!data.results) {
                this.serviceError = this.externalURLs.reporter_project_search_error_message;
                this.loading = false;

                return false;
            }

            this.setHomeMapSearchId(data.meta.search_id)
            let states = data.results[0].states,
                state,
                territoriesToExclude = [
                    'AS',
                    'VI',
                    'GU',
                    // 'PR',
                    // 'DC'
                ];



            this.heatMapMinValue = data.results[0].projects_funding_amount;
            this.heatMapMaxValue = 0;

            this.mapData = [];

            for (let i = 0; i < states.length; i++) {
                state = states[i];

                // console.log(d[d.indexOf(this.stateCodeToName[state.code])], this.stateCodeToName[state.code], this.stateCodeToName[state.code] === d[d.indexOf(this.stateCodeToName[state.code])]);

                if (territoriesToExclude.indexOf(state.code) === -1 && this.stateCodeToName[state.code]) {
                    // console.log(this.stateCodeToName[state.code]);
                    this.mapData.push({
                        name: this.stateCodeToName[state.code],
                        value: state.projects_funding_amount,
                        projects: state.projects_count,
                    });

                    if (state.projects_funding_amount < this.heatMapMinValue) {
                        this.heatMapMinValue = state.projects_funding_amount;
                    }

                    if (state.projects_funding_amount > this.heatMapMaxValue) {
                        this.heatMapMaxValue = state.projects_funding_amount;
                    }

                // } else {
                    // console.log(d[d.indexOf(this.stateCodeToName[state.code])]);
                }
            }

            this.setHomePageMapData({
                heatMapMinValue: this.heatMapMinValue,
                heatMapMaxValue: this.heatMapMaxValue,
                mapData: this.mapData
            });

            if (callback) {
                callback();
            }
        },
        resizeChart () {
            if (this.animate) {
                this.eChart.setOption({
                    animation: false
                });
            }

            try {
                this.eChart.resize();
            } catch (er) {
                
            }

            if (this.resizeTimeout) {
                clearTimeout(this.resizeTimeout);
            }

            this.resizeTimeout = setTimeout(() => {
                this.animate = true;

                this.eChart.setOption({
                    animation: true
                });
            }, 300);
        },
        eChartsBuild () {
            this.loading = false;
            // console.log(geoData);
            echarts.registerMap('USA', this.geoData, {
                Alaska: {
                    left: -126,
                    top: 22,
                    width: 15,
                    height: 9
                },
                Hawaii: {
                    left: -110,
                    top: 24,
                    width: 4.956631
                },
                'Puerto Rico': {
                    left: -72,
                    top: 24,
                    // use actual size
                    width: 1.643082
                }
            });

            var mapSettings = {
                    stateNameColor: '#000',
                    stateBorderColor: '#fff',
                    stateBackgroundColor: 'rgb(247, 245, 241)',
                    stateBackgroundColorOnHover: '#FAECAF',
                    stateBorderColorOnHover: '#FBA803',
                    stateBorderWidth: 1,
                    stateBorderWidthOnHover: 2
                },
                option = {
                    tooltip: {
                        confine: true,
                        trigger: 'item',
                        showDelay: 0,
                        transitionDuration: 0.3,
                        formatter: function (params) {
                            let tooltipContent = '<strong>' + params.name + '</strong><br>' + 'Projects: ' + window.filters.addCommas(params.data.projects) + '<br>Funding: ' + window.filters.formatCurrency(params.value);

                            return tooltipContent;
                        }
                    },
                    visualMap: {
                        show: false,
                        left: 'right',
                        min: this.homePageMapData.heatMapMinValue,
                        max: this.homePageMapData.heatMapMaxValue,
                        inRange: {
                            color: ['#a8e8b6', '#59b6bc', '#3fa6bd', '#3fa6bd', '#0081bf', '#0081bf', '#0067aa', '#0067aa', '#003884']
                            // color: ['#a8e8b6', '#59b6bc', '#3fa6bd', '#0081bf', '#0067aa', '#003884']
                        },
                        text:['High','Low'],
                        // calculable: true
                    },
                    toolbox: {
                        show: false
                    },
                    series: [
                        {
                            // name: 'USA PopEstimates',
                            type: 'map',
                            roam: false,
                            map: 'USA',
                            // itemStyle:{
                            //     emphasis:{label:{show:true}}
                            // },
                            zoom: 1.2,
                            // scaleLimit: {
                            //     min: 1.2,
                            //     max: 1.2
                            // },
                            label: {
                                show: false
                            },
                            itemStyle: {
                                areaColor: mapSettings.stateBackgroundColor,
                                borderColor: mapSettings.stateBorderColor,
                                borderWidth: mapSettings.stateBorderWidth
                            },
                            emphasis: {
                                itemStyle: {
                                    areaColor: mapSettings.stateBackgroundColorOnHover,
                                    borderColor: mapSettings.stateBorderColorOnHover,
                                    borderWidth: mapSettings.stateBorderWidthOnHover
                                },
                                label: {
                                    show: false
                                }
                            },
                            // textFixed: {
                            //     Alaska: [20, -20]
                            // },
                            data: this.homePageMapData.mapData,
                            // data:[
                            //     {name: 'Alabama', value: 4822023},
                            //     {name: 'Alaska', value: 731449},
                            //     {name: 'Arizona', value: 6553255},
                            //     {name: 'Arkansas', value: 2949131},
                            //     {name: 'California', value: 38041430},
                            //     {name: 'Colorado', value: 5187582},
                            //     {name: 'Connecticut', value: 3590347},
                            //     {name: 'Delaware', value: 917092},
                            //     {name: 'District of Columbia', value: 632323},
                            //     {name: 'Florida', value: 19317568},
                            //     {name: 'Georgia', value: 9919945},
                            //     {name: 'Hawaii', value: 1392313},
                            //     {name: 'Idaho', value: 1595728},
                            //     {name: 'Illinois', value: 12875255},
                            //     {name: 'Indiana', value: 6537334},
                            //     {name: 'Iowa', value: 3074186},
                            //     {name: 'Kansas', value: 2885905},
                            //     {name: 'Kentucky', value: 4380415},
                            //     {name: 'Louisiana', value: 4601893},
                            //     {name: 'Maine', value: 1329192},
                            //     {name: 'Maryland', value: 5884563},
                            //     {name: 'Massachusetts', value: 6646144},
                            //     {name: 'Michigan', value: 9883360},
                            //     {name: 'Minnesota', value: 5379139},
                            //     {name: 'Mississippi', value: 2984926},
                            //     {name: 'Missouri', value: 6021988},
                            //     {name: 'Montana', value: 1005141},
                            //     {name: 'Nebraska', value: 1855525},
                            //     {name: 'Nevada', value: 2758931},
                            //     {name: 'New Hampshire', value: 1320718},
                            //     {name: 'New Jersey', value: 8864590},
                            //     {name: 'New Mexico', value: 2085538},
                            //     {name: 'New York', value: 19570261},
                            //     {name: 'North Carolina', value: 9752073},
                            //     {name: 'North Dakota', value: 699628},
                            //     {name: 'Ohio', value: 11544225},
                            //     {name: 'Oklahoma', value: 3814820},
                            //     {name: 'Oregon', value: 3899353},
                            //     {name: 'Pennsylvania', value: 12763536},
                            //     {name: 'Rhode Island', value: 1050292},
                            //     {name: 'South Carolina', value: 4723723},
                            //     {name: 'South Dakota', value: 833354},
                            //     {name: 'Tennessee', value: 6456243},
                            //     {name: 'Texas', value: 26059203},
                            //     {name: 'Utah', value: 2855287},
                            //     {name: 'Vermont', value: 626011},
                            //     {name: 'Virginia', value: 8185867},
                            //     {name: 'Washington', value: 6897012},
                            //     {name: 'West Virginia', value: 1855413},
                            //     {name: 'Wisconsin', value: 5726398},
                            //     {name: 'Wyoming', value: 576412},
                            //     {name: 'Puerto Rico', value: 3667084}
                            // ]
                        }
                    ]
                };

            this.eChart.setOption(option);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.tile {
    position: relative;
    overflow: hidden;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: $teal-charts;
    }
}

.sub-title {
    display: block;
    margin-bottom: -18px;
    font-size: 13px;
    font-weight: 700;
    color: #757575;
}

.search-tile-outer {
    position: absolute;
    padding: $tile-spacing;

    @media screen and (max-width: $screen-sm) {
        padding-left: 10px;
        padding-right: 10px;
    }

    &.animate {
        transition: 0.3s;

        // &,
        // &.search-tile {
        //     transition: 0.3s;
        //     // transition: 0s;
        // }
    }
    @media screen and (max-width: $screen-md-max) {
        // override fancy positioning on mobile
        position: relative;
        top: auto !important;
        left: auto !important;
        bottom: auto !important;
        right: auto !important;
        width: 100% !important;
        height: auto !important;
    }
}

.search-tile {
    $tile-padding: 24px;

    padding: $tile-padding * 0.9 $tile-padding;
    background-color: #fff;
    border-radius: $input-radius;
    box-shadow: 0 2px 8px 0px rgba(0,0,0,0.25);
    height: 100%;
    transition: 0.3s;
    outline: none;
    overflow: hidden;

    @media screen and (max-width: $screen-sm) {
        padding-left: 10px;
        padding-right: 10px;
    }

    &:hover:not(.active),
    &:focus:not(.active) {
        box-shadow: 0 4px 16px 0px rgba(0,0,0,0.45);
    }

    &.active {
        height: auto;
        transition: 0s;
        overflow: visible;

        .tile-content {
            position: relative;
        }

        .tile-description {
            position: absolute;
        }
    }
}

.field-title {
    margin-bottom: 1em;
    color: #212529;
    // &:after {
    //     background-color: $blue;
    // }

    a {
        &:hover, &:active, &:focus {
            text-decoration: none;
        }
    }
}

.tile-content-wrapper {
    position: relative;
}

.tile-content {
    position: absolute;
    top: 0;
    left: 0;
    // margin-top: 3px;
    margin-left: -15px;
    padding-left: 0;
    padding-right: 0;
    width: calc(100% + #{$grid-gutter-width});
    transition: 0.3s;
}

.tile-description {
    // margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
}

.hide-content {
    opacity: 0;
    pointer-events: none;
}

.legacy-link {
    display: inline-block;
    margin: 10px 0 20px;
    padding: 5px 8px 5px 12px;
    background-color: $grey-lighter;
    border-radius: 15px;
    color: #333;
    font-weight: 700;

    @media screen and (max-width: 1250px) and (min-width: 1200px) {
        font-size: 13px;
    }

    &:hover,
    &:focus {
        text-decoration: none;
        background-color: #E7F1FB;
        color: $blue;

        .arrow-right {
            fill: $blue;
        }
    }

    .arrow-right {
        position: relative;
        margin-left: 5px;
        top: -1px;
        width: 15px;
        fill: #333;
    }
}

</style>
